import React from "react";
import {Button, Row, Col, Collapse} from 'antd';
import { Link } from "gatsby";

import Layout from '../components/layout';
// import "./style.less";
import FooterBox from '../components/footerBox';

import upIcon from '../images/icon-ui-arrow-up.svg';
import downIcon from '../images/icon-ui-arrow-down.svg';

import Routes from '../constant/routes';

const FAQ = [
  {key: '1', title: 'What is TicketTamer?', message: `TicketTamer is a web-based system for fixing your traffic and misdemeanor tickets as easily as possible and at a reasonable price.  It is offered by ReubenLaw LLC., which is located at P.O. Box 39023, St. Louis, Mo., 63109. Phone: (314) 728-4444. It is not a referral service. 
  
  When you upload your ticket into TicketTamer, you are hiring ReubenLaw LLC to represent you for the ticket(s) you have sent us, and agree to our Terms of Service and Privacy Policy. This is a typical attorney-client relationship, and all communications between you and ReubenLaw LLC will be privileged and confidential.
  `},
  {key: '2', title: 'What Will TicketTamer Do for Me?', message: `We represent you before the court on all matters relating to your ticket(s).  Our primary goal is to get your moving violation reduced to a non-moving violation and get you through your ticket as easily and as quickly as possible. Getting your moving violation reduced to a non-moving violation could save you hundreds of dollars a year in higher insurance costs, and keep points off your driving record.
  
  Once we have entered on your behalf, you don’t have to worry about appearing in court, or even the court date.  We do that for you, including the worrying.:)
  `},
  {key: '3', title: 'Will I still have to pay a fine?', message: `Yes. In most cases, your TicketTamer lawyer will work out a deal with the prosecutor to reduce the traffic offense down to a non-moving violation. You will still be charged a fine for the ticket – and it will generally be more than if you just pay the ticket. Fines between $150-$300 are common. You will also be assessed court costs as well, and they tend to run between $25 and $100.  However, you won’t get any points on your record, which could save you hundreds of dollars a year in higher insurance since your ticket will not be reported to the state or your insurance company for three to five years.`},
  {key: '4', title: 'How much do your legal services cost?', message: <div>
    <Link to={Routes.PRICING}>Check out our great prices here</Link>. &nbsp;Our attorney fees do not include your fine and court costs.
  </div>},
  {key: '5', title: 'How much would my insurance go up if I just plead guilty and take the points?', message: `It depends on your situation, including where you live, how many points you already have, and whether you have younger drivers on your policy.  
  
  Here is what we can say for certain: When you get a ticket, you will lose any good driver discount you may have, and be slapped with a surcharge for the ticket. The amount will vary, but in our experience, just one ticket will increase your rates a few hundred dollars a year – for three to five years.
  `},
  {key: '6', title: 'What if the cop gave me more than one ticket?', message: `Each ticket is treated as a separate ticket, with a $75 basic ticket fee plus any other applicable charges. For jurisdictions that require driving records or court appearances, it will only be necessary to pay those charges once, regardless of how many tickets you receive on that stop.`},
  {key: '7', title: 'What is a court appearance fee, and how do I know if I have to pay it?', message: `Most jurisdictions do not require our personal appearance to work out a deal in your favor. However, some do, such as the Circuit Courts of St. Louis and St. Charles Counties.  Courts may also require an appearance because of unique circumstances, such as speeding more than 25 mph over the speed limit.`},
  {key: '8', title: 'How long do I have to pay you?', message: `Payment for legal services is due when you hire us, but we are glad to let you pay your attorney fee over time. However, we will need a retainer of half your attorney fee to get started on your case, and we will need payment in full before we can sign off on your deal.`},
  {key: '9', title: 'How long will it take for you to handle my ticket?', message: `Most tickets generally take around four weeks to settle. Some take longer, especially if the ticket is not filed with the court immediately. In some cases, the prosecutor may require additional documentation or course completion before they will issue a recommendation. We will let you know of any status changes by email when they happen, but you can always get case updates anytime on our website. If you still have questions, just let us know.`},
  {key: '10', title: 'How do I pay my fine?', message: <div>
    You have two options.  You can pay it yourself or hire us to take care of it for you for a $25 handling fee. We guarantee the fine will be paid properly and on time.
  <br/><br/>
  If you prefer to pay it yourself, then what you will need to do will depend upon the court. We will provide you with specific instructions for getting it taken care of yourself. However, we are not responsible for the payment of your fine or the timely completion any conditions the prosecution may place on your recommendation, such as traffic school. As a legal matter, our representation ends when we deliver the deal to you, unless you decide to hire us to pay your fine for you.
  <br/><br/>
  <strong><u>If you do not hire us to pay the fine, ReubenLaw LLC is not responsible for the payment of your ticket or the satisfaction of any other conditions the deal may require. This is entirely your responsibility and should be taken care of as soon as you can after you get your deal.</u></strong>
  <br/><br/>
  The prosecutor’s recommendation is typically only good for a specific period of time, and will be withdrawn if you haven’t paid your ticket or satisfied any other conditions on time. In that case, we would be glad to continue to represent you, but will need to charge you for a new ticket.
  </div>},
];

const FAQPage = () => {
  
  return (
    <Layout title="FAQ">
      <section className="Section__FAQ" style={{paddingTop: 0}}>
        <div className="max-width-container">
          <Row justify="center">
            <Col md={16}>
              <div className="Display-2BlackCenter Section__FAQ__Title">Frequently Asked Questions</div>
              <div>
                <Collapse accordion ghost expandIconPosition="start" expandIcon={({isActive}) => <img src={isActive ? upIcon : downIcon} alt="arrow icon" style={{margin: 0}} />}>
                  {FAQ.map(item => (
                    <Collapse.Panel style={{borderBottom: 'solid 1px #d8d8d8'}} key={item.key} header={<div className="SubHeadingBlackLeft Section__FAQ__Header">{item.title}</div>}>
                      <div className="BodyBlackLeft" style={{whiteSpace: 'pre-line'}}>{item.message}</div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
              <div className="BodyBlackCenter mt40 mb8">Can't find what you're looking for?</div>
              <div style={{textAlign: 'center'}}>
                <Link to={Routes.CONTACT_US}><Button>Ask Us a Question</Button></Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterBox />
    </Layout>
  );
}

export default FAQPage;
